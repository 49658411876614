@charset "UTF-8";
/* CSS Document */
.s-boton{padding-bottom: 25px;}
.s-boton a {
  padding: 10px 20px;
  background:black;
  color: white;
  margin: 4px;
  font-weight: 600;
  text-decoration: none;
}
button.btn[disabled] {     background: gray; }

body{margin:auto;}
h1{
	font-family: 'Gill Sans';
    font-weight: 500;
    font-style: normal;
    font-size: 4vw;
    line-height: 1;
    margin: 0;}
h2{
	font-family: 'Gill Sans';
    font-weight: bold;
    font-style: normal;
}
p{ font-family: 'Gill Sans';
    font-weight: 300;
    font-style: normal;
	font-size:24px;
}
b,a,h3,h4,h5,input,select,td,th{
	font-family: 'Gill Sans';
}
.container{
	width:  100%;
	margin:0;
	display: block;
}
/*MENU*/
.menu{
	display: inline-flex;
	width: 100%;

}
.logo-left{
	width: 42%;
	background: white;
	display: block;
	padding-left: 20px;
	margin: auto;
}
.logo-left img{
	max-width: 350px
}
.logo-right{
	background-color: black;
	width: 58%;
	padding-right: 30px;
}
.logo-right img{
	max-width: 100px;
	float: right;
	margin-top:8px
}
/*content*/
.banner{width: 100%;display: inline-flex;overflow: hidden;}
.banner-left{width: 42%;display:block;overflow: hidden;}
.banner-img{width: 100%;background-image:url("assets/image/top.jpeg");height: 100%;background-size: cover;}
.banner-img img { width: 92rem;}
.banner-right{width:58%}
.banner-text{padding: 60px 8vw 60px 60px}
.banner-text .plan-p{text-align: justify;line-height: 33px;}
.banner-text b{letter-spacing: 2px}
.box-plan {display: -webkit-inline-box;width: 100%; margin:40px 0}
.plan-e{width:45%;margin-right: 5%}
.plan-e h2{color:#F8B56D;letter-spacing: 2.5px;font-size: 26px;height: 65px;}
.plan-e p{font-size: 17px;line-height: 23px;text-align: justify;height: auto;min-height: 4.5vw;display: flex;}
.plan-e a{background: rgb(248, 181, 109);color: white;padding: 15px 0px;box-shadow: rgb(248 181 109) 0px 0px 0px 4px;border: 1px solid white;display: block;width: 100%;text-align: center;text-decoration: none;    margin-top: 30px;}
.plan-e b{font-size: 24px;font-weight: bold}
.image-logo {
  height: 80px;
  text-align: center;
  overflow: auto;
}
.image-logo img {
  height: 65px!important;
}

/*new code*/
th.orange{
background-color: #F8B56D;
}
th.redwine{
  background-color: #711B45;
  color:white
}
td.orange,.orange a{
  color: #F8B56D;
  font-weight: 700;
  }
td.redwine,.redwine a{
    color: #711B45;
    font-weight: 700;
  }
  
.box_new {
  display: grid;
  grid-column: auto;
  grid-template-columns: 50% 50%;
}
.box_new #price {
  color: black;
  font-size: 40px;
  line-height: 0;
  padding: 35px 0 30px 0;
  margin: 0;
  background: #f8f7f6;
}
.box_new img {
  height: 65px;
  margin: auto;
}
.t-plan {
  padding-top: 30px;box-shadow: -1px 1px 12px 0px rgb(199 199 199 / 75%);
}
  .e-plan h2{color:#F8B56D;font-size: 30px;margin:0}
  .s-plan h2{color:black;font-size: 30px;margin:0}
  .box_new #price:after {
    content: "Precio";
    font-size: 16px;
    display: block;
    padding-top: 32px;
    font-weight: 600;
}
.e-boton a:hover {
  background: black;
} 
.e-boton {
    padding-bottom: 25px;
}
.e-boton a {
  padding: 10px 20px;
  background: #F8B56D;
  color: white;
  margin: 4px;
  font-weight: 600;
  text-decoration: none;
}
.omb{display: none;}

@media(max-width:1234px){.e-boton,.s-boton{display: flex;}}
@media(max-width:960px){.e-boton,.s-boton{display: grid;}}
@media(max-width:720px){
  .omb{display: table-cell;padding: 6px;
    background: #f5eac1;}
    .table-container{padding: 0!important;}
    .table-container h1{font-size: 24px;margin-bottom: 25px!important;}
    .table-container h1:after{
      bottom: 9px!important;
      width: 30%!important;
  }
  .opc{display: none;}
  .essentials-text{min-height: 300px;width: 100%;}
  .essentials-plan{width: 100%!important;background: white}
  .e-plan h2{text-align: center;padding-bottom: 15px;}
  .box_new #price{
    margin-bottom: -20px;
    margin-left: -20px;
    padding: 38px;
    text-align: center;
  }
}

/*end code new*/
.plan-s{width:45%;margin-left: 5%}
.plan-s h2{color:#711B45;letter-spacing: 2.5px;font-size: 26px;height: 65px;}
.plan-s p{font-size: 17px;line-height: 23px;text-align: justify;height: auto;min-height: 4.5vw;display: flex;}
.plan-a{background: #711B45;color: white;padding: 15px 0px;box-shadow: #711B45 0px 0px 0px 4px;border: 1px solid white;display: block;width: 100%;text-align: center;text-decoration: none;    margin-top: 30px;}
.plan-s b{font-size: 24px;font-weight: bold}
.mision{width: 100%;display: inline-flex;overflow: hidden;margin: 40px 0;}
.mision-left{width: 40%;display:block;overflow: hidden;}
.mision-right {
    width: 60%;
    background-image: url("assets/image/vinos.jpeg");
    background-size: cover;
}
.mision-img{width: 100%}
.mision-img img { width: 63rem;}
.mision-text{padding: 80px}
.mision-text h1{color:#711B45}
.mision-text p{text-align: justify}
.essentials{width: 100%;display: inline-flex;height:600px;/*background: #F5EAC1*/    background: #f8f7f6;}
.essentials-text{width: 50%;background-image: url("assets/image/esencial_box.webp");background-size: cover;background-position-x: center;}
.e-text{display: flex;margin-left:125px;height: 100%}
.e-text-left{width: 45%;margin-right:5%;display:block;overflow: hidden;margin-top:-120px}
.e-text-left img{width: 20.5vw}
.e-text-left p{font-size:17.8px;text-align: justify}
.e-text-right{width: 45%;margin-left:5%;overflow: hidden; background-size: cover;
  background-image: url(https://dev-felipemotta.billcentric.com/assets/image/essential2.jpeg);
}
.e-text-right h1{font-size:5vw;color:#F8B56D;margin-bottom: 31px;position: absolute;margin-top:-5vw}
.e-text-right img {width: -webkit-fill-available;}
.essentials-plan{width:50%}
.e-plan{background-color: white;text-align: center;width: 55%;margin: auto;vertical-align: middle;margin-top: 9%;}

.e-plan p{color:#F8B56D}
.e-boton-new a{ padding: 5px 15px;background:black;color: white;margin:4px }
.description{
  font-size: 15px;
}
/**/
.signature{width: 100%;display: inline-flex;height: auto;background:black;margin-top: -4px;min-height: 600px;}
.signature-text{width: 50%; background-image: url("assets/image/signature_back.webp");background-size: cover;background-position-x: center;}
.s-text{display: flex;margin-right:80px}
.s-text-left{width: 45%;margin-right:5%;display:block;overflow: hidden;margin-top:-120px}
.s-text-left img{width: 20.5vw}
.s-text-left p{font-size:20px;text-align: justify;color:white}
.s-text-left h1{color:white}
.s-text-right{width: 45%;margin-left:5%;overflow: hidden; background-image: url(https://dev-felipemotta.billcentric.com/assets/image/signature2.jpeg); background-size: cover;}
.s-text-right h1{font-size:2.9vw;color:black;margin-bottom: 31px;position: absolute;margin-top:-75px}
.s-text-right img {width: -webkit-fill-available;}
.signature-plan{width:50%;    display: inherit;}
.s-plan{background-color: white;text-align: center;width: 55%;margin: auto;vertical-align: middle}
.s-plan #first-txt{font-size: 3vw;color:black;}
.s-plan p{color:black}
.unete {
    padding: 7%;
}
.unete h1{font-size:2.2vw;text-align: center;}
.unete-w{width: 65%;margin:auto}
.unete a{background: black;color: white;padding: 15px 0px;box-shadow: black 0px 0px 0px 4px;border: 1px solid white;display: block;width: 25%;text-align: center;text-decoration: none;margin:auto;font-size:22px}
.unete img{margin:auto;display: block;height: 130px}
.image-plan {
    background-color: #F5EAC1;
    height: auto;
    margin: auto;
    display: inline-flex;
    width: 100%;
}
.two-column{width: 70%;margin: auto;display: flex;margin-top:-7%}
.column-a{width:30%;display:block; padding-bottom: 50px;}
.column-a img{width:100%}
.column-a h1{font-size: 2vw;font-weight: bold;margin-top:15px}
.column-a p{margin: 0}
.column-b{width: 67%;display: block;margin-left:3%;background-image: url(https://dev-felipemotta.billcentric.com/assets/image/bottom.jpeg); background-position-y: bottom;
  background-size: cover; min-height: 500px;
}
.column-b img{width: 100%}
.wine-box {overflow: hidden;margin: auto;margin-left: 25px;    margin-top: 80px;}
.wine-box .wine:nth-of-type(4n+1) {margin-left: 20px;}
.wine {width: 22.6%;float: left;margin: 20px;}
.back-wine{background: #F5EAC1;text-align: center;}
.back-wine img{width: -webkit-fill-available;}
/*.wine{width:22%;height: 384px;background: #FAF5E2;padding:10px;display: inline-grid;margin:10px;margin-bottom: 100px;}
.wine img{height: 348px;margin: auto;display:block;padding: 20px}*/

footer{background: #000000;height:80px;
  margin-top: -25px;}
/*plan*/
.text-head{padding: 80px 0}
.text-head h1{font-size:35px;text-align: center;}
.content-plan {background-color: #FAF5E2;padding: 20px 0;}
.column-plan-b {
    padding: 29px;
    text-align: right;
}
.plan-select{background-color: white;margin: 40px 80px;    box-shadow: 1px 1px 6px 0px #ece4c7;}
.column-plan{width: 100%;margin: auto;display: flex;}
.column-plan-a{width: 50%;padding: 40px}
.column-plan-a h1{font-size:18px}
.column-plan-a h1 b{background: black;color: white;padding: 9px 10px 4px;border-radius: 30px}.s-text-right img
.column-plan-b{width: 50%;padding: 40px;text-align: right;line-height: 0}
.column-plan-b h1{font-size:28PX;font-weight: bold;}
.column-plan-b a{text-decoration: underline;font-size:20px}
.column-plan-b img {width: 10.5vw;}
.column-plan-b p{margin:0}

.content-form{background-color: white;margin: 40px 80px;    box-shadow: 1px 1px 6px 0px #ece4c7;padding: 40px}
.content-form h1{font-size:18px}
.content-form h1 b{background: black;color: white;padding: 9px 10px 4px;border-radius: 30px}
.process{display: flex}
hr{width:60%;margin-left: auto;margin-right: auto;height: 1px;background-color:#666;opacity: 0.5;}
.text-send{background: #151515}
.text-send-space{width:80%;color:white;margin: auto;padding: 80px}
.text-send-space h1{font-size:35px;text-align: center}
.form-group {
    width: 90%;
    margin: 20px;
}
.form-group-solo {
    width: 100%;
		margin: 0 20px;
}

.form-group input, .form-group select,.form-group-solo input {
    background: #FDFBF5;
    border: none;
    box-shadow: 0px 0px 3px 0px #05050552;
    padding: 9px;
}
.two-column-form {
    display: flex;
    width: 100%;
    justify-content: center;
}
.rendered-form {
    overflow: hidden;
		margin: 40px 0;
}
.one-c{width:100%;}
.tr-c{width:33%}
.card {
    margin: 0 20px;
}
select#mes-exp,select#ano-exp,input#CCV {
    width: 100%;
}
.bot {
    margin: auto;
    text-align: center;
    height: 70px;
		margin-top:50px;
}
button#suscribirte {
    background: black;
    border: solid 1px white;
    box-shadow: 0 0 0px 4px black;
    line-height: 31px;
    font-size: 18px;
    font-family: 'Gill Sans';
    color: white;
    font-weight: bold;
}

button#suscribirte[disabled] {     background: gray; box-shadow: 0 0 0px 4px gray; }

button#suscribirte-essencial {
    background: #F8B56D;
    border: solid 1px white;
    box-shadow: 0 0 0px 4px #F8B56D;
    line-height: 31px;
    font-size: 18px;
    font-family: 'Gill Sans';
    color: white;
    font-weight: bold;
}
.menu-right {
  display: flex;
  justify-content: space-evenly;
  align-items: initial;
    z-index: 3;
    width: 20%;
    top: 77px;
    position: absolute;
    right: 0;}
.b-menu {
    width: 51%;
    text-align: center;
    margin-right: 0px;
    /* display: flow-root; */
    float: right;
		padding: 15px;
		background: #FAF5E2 ;
		border: none;
		box-shadow: 0 0 5px 0px #9e9e9e;
		font-family: 'Gill Sans';
		font-size: 20px
}
.b-menu:hover {
    background: black;
    color: #FAF5E2;
}
button.b-menu a:hover {
    color: white;
}
.success{
		width: 75%;
    margin: auto;
    margin-top: 70px;
    height: 400px;
	}
	.thanks{
    text-align: center;
    line-height: 30pc;}
		.b-menu a {
    color: black;
}
/*dashboard*/
.dashboard{
	background: #F5EAC1;
	display: flex;
	min-height: 550px
}

.column-profile{width:30%}
.column-profile a{
	color:black;
	text-decoration: none;
}
.column-profile li {
    list-style: none;
    font-size: 20px;
}
.column-data {
    width: 70%;
    background: #f8f3df;
}
.column-data h1{font-size: 32px}

	.content-data {
    margin: 80px;
}
.form-horizontal input{    width: 80%;
    max-width: 400px;}
.form-horizontal button{
	background: black;
	border: none;
	padding: 10px 20px;
	color:white;
}
.form-horizontal button:hover{
	background: #F5EAC1;
	color:black;
	border:black solid 1px;
}
.active {
    background: black!Important;
    color: white!important;
}
.banner-profile {
    background-image: url(assets/image/background-wine.png);
    background-size: cover;
}
.banner-profile:before {
    content: ".";
    background: #0000006b;
    /*width: 100200px;*/
    height: 200px;
    position: absolute;
    z-index: 1;
}
.banner-profile h1 {
    position: relative;
    text-align: center;
    font-size: 35px;
    color: white;
    line-height: 200px;
    z-index: 2;
}
.column-profile ul {
    margin: auto;
    text-align: center;
		padding:0;
		margin-top:80px;
}
.column-profile li {
    margin: 10px 0;
    list-style: none;
    font-size: 20px;
		width:100%;
}
.column-profile li a {
    background: white;
    padding: 10px;
    display: block;
    width: 60%;
    text-align: center;
		margin:auto;
}
.column-profile li a:hover {
    background: black;
    color: white;
}

:root {
    --bg-table-stripe: #f6f6f5;
    --b-table: #e3e3e2;
    --caption: #242423;
}

table {
    background-color: transparent;
    border-collapse:collapse;
  	font-family: Arial, Helvetica, sans-serif
}

th {
    text-align:left
}

.dcf-txt-center {
      text-align: center!important
    }

    .dcf-txt-left {
      text-align: left!important
    }

    .dcf-txt-right {
      text-align: right!important
    }

.dcf-table caption {
      color: var(--caption);
      font-size: 1.13em;
      font-weight: 700;
      padding-bottom: .56rem
    }

    .dcf-table thead {
      font-size: .84em
    }

    .dcf-table tbody {
      border-bottom: 1px solid var(--b-table);
      border-top: 1px solid var(--b-table);
      font-size: .84em
    }

    .dcf-table tfoot {
      font-size: .84em
    }

    .dcf-table td, .dcf-table th {
      padding-right: 1.78em
    }

    .dcf-table-bordered, .dcf-table-bordered td, .dcf-table-bordered th {
      border: 1px solid var(--b-table)
    }

    .dcf-table-bordered td, .dcf-table-bordered th, .dcf-table-striped td, .dcf-table-striped th {
      padding-left: 1em;
      padding-right: 1em
    }

    .dcf-table-bordered tr:not(:last-child), .dcf-table-striped tr:not(:last-child) {
      border-bottom: 1px solid var(--b-table)
    }

    .dcf-table-striped tbody tr:nth-of-type(2n) {
      background-color: var(--bg-table-stripe)
    }

    .dcf-table thead td, .dcf-table thead th {
      padding-bottom: .75em;
      vertical-align: bottom
    }

    .dcf-table tbody td, .dcf-table tbody th, .dcf-table tfoot td, .dcf-table tfoot th {
      padding-top: .75em;
      vertical-align: top
    }

    .dcf-table tbody td, .dcf-table tbody th {
      padding-bottom: .75em
    }

    .dcf-table-bordered thead th {
      padding-top: 1.33em
    }

    .dcf-wrapper-table-scroll {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-bottom: 1em;
      position: relative;
      right: 50%;
      width: 100vw
    }

    @media only screen and (max-width:42.09em) {
      .dcf-table-responsive thead {
        clip: rect(0 0 0 0);
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
        white-space: nowrap
      }
      .dcf-table-responsive tr {
        display: block
      }
      .dcf-table-responsive td {
        -webkit-column-gap: 3.16vw;
        -moz-column-gap: 3.16vw;
        column-gap: 3.16vw;
        display: grid;
        grid-template-columns: 1fr 2fr;
        text-align: left!important
      }
      .dcf-table-responsive.dcf-table-bordered, .dcf-table-responsive.dcf-table-bordered thead th {
        border-width: 0
      }
      .dcf-table-responsive.dcf-table-bordered tbody td {
        border-top-width: 0
      }
      .dcf-table-responsive:not(.dcf-table-bordered) tbody tr {
        padding-bottom: .75em
      }
      .dcf-table-responsive:not(.dcf-table-bordered) tbody td {
        padding-bottom: 0
      }
      .dcf-table-responsive:not(.dcf-table-bordered):not(.dcf-table-striped) tbody td {
        padding-right: 0
      }
      .dcf-table-responsive.dcf-table-bordered tbody tr:last-child td:last-child {
        border-bottom-width: 0
      }
      .dcf-table-responsive tbody td:before {
        content: attr(data-label);
        float: left;
        font-weight: 700;
        padding-right: 1.78em
      }
    }

.dcf-overflow-x-auto {
      overflow-x: auto!important;
      -webkit-overflow-scrolling: touch
    }

.dcf-w-100\% {
  width: 100%!important;}
	.dropbtn {
  background-color: transparent;
  color: black;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
button.dropbtn:before {
    content: "";
    width: 19px!important;
    height: 20px;
    background-image: url(assets/image/more.png);
    /* width: 10px; */
    background-size: cover;
    display: inline-block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}
.pc-view {
    padding: 20px 40px;
}
.pc-view p {
    color: black;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
}

@media(max-width:1594px){.wine{width:21%;}}
@media(max-width:1044px){
	.wine{width:27%;}
	.wine-box .wine:nth-of-type(3n+1){clear: left;margin-left: 20px;}}

@media(max-width:1280px){
	.plan-s h2, .plan-e h2{font-size:23px}
	/*wine box*/

}
.footer {
    height: auto;
    display: flow-root;
}
.mobile-show{display:none}
@media (max-width:720px){
  footer {
    background: #000000;
    height: 150px;
}
  .mtc .mat-row{
    display: block!important;
    padding: 0 20px;
  }
  .mtc mat-cell {
    position: initial !important;
    color: black !important;
    font-size: 16px;
  }
  .cdk-column-nameHolderCreditCard:before{
    content:"Nombre:";
    width: 85px;
    display: -webkit-inline-box;
    padding-left: 0!important;
  }
  .mtc .cdk-column-actions {
    margin-top: 20px;
  }
  .cdk-column-expMonthCreditCard:before{
    content:"Tarjeta: ";
    width: 85px;
    display: -webkit-inline-box;
  }
  .cdk-overlay-pane{
    max-width: 100%!important;
    width: 90%!important;
    overflow: auto!important;
  }#cdk-overlay-1{width: 100%!important}
  #cdk-overlay-3{width:100%!important}
	.footer{padding: 20px}
	.footer p{font-size: 16px;}
	.pc-view{display: none}
	.mobile-show{display:block}
	.s-text-left h1 {
color: white;
font-size: 8vw;
}
	#cdk-overlay-0{max-width: 100%!important;
    width: 88%!important;}
		.mat-dialog-container{    background: #f8f3df!important;}
	.img-sub{width: auto!important;
    height: 55px!important;
    padding-bottom: 0!important;
}
	.menu-right{width: 50%;}
	.content-data{margin:20px}
	.banner-profile{height: 200px}
	.banner-profile h1{line-height: 1;padding-top: 70px}
  .column-profile{width:100%;padding: 20px 0;}
	.column-profile ul{margin-top:0;}
	.column-data{width:100%}
	.dashboard{display: block}
	.banner{display: block;}
	.banner-left {width: 100%;height: 350px;}
	.banner-right {width: 100%;}
	.container{overflow: hidden;}
	.logo-left,.logo-right{padding:16px 0;width: 50%;text-align: center;}
	.logo-left img{width:170px;}
	.logo-right img{width:110px;float:none}
	.banner-text {  padding: 30px;}
	.banner-text h1 {font-size: 36px;}
	.plan-e p,.plan-s p{height: inherit;min-height: 240px!important}
	.plan-s h2, .plan-e h2 {font-size: 23px;margin-bottom: 34px;}
	.plan-e a,.plan-s a{width: 100%;font-size: 12px;}
	.plan-e b,.plan-s b{font-size:22px}
	.mision{width: 100%;display:flex;height: auto;flex-flow: column-reverse;}
	.mision-left{width: 100%;}
	.mision-text {padding: 30px;}
	.mision-text h1{font-size:36px}
	.mision-right{width:100%;height: 400px}
	.essentials{display: block;height: auto;margin-bottom: 40px;}
	.essentials-text{min-height: 300px;width: 100%;}
	.e-text{margin-left: 30px}
	.e-text-left{width: 70%}
	.e-text-left img { width: 100%;}
  .s-boton a{padding: 18px;
    background: black;
    color: #fff;
    display: block;
    width: 75%;
    text-align: center;
    margin: auto;
    margin-bottom: 9px;
  }
	.e-text-right img {width: auto;width:500px;height: 600px;}
	.e-text-right h1{font-size: 8vw;margin-top: -50px;}
	
	.e-plan{background: none;width: 100%;margin-top:0}
	.e-plan p{font-size: 20px;}
	.t-plan{padding: 20px;    text-align: initial;}
	.e-plan #first-txt{font-size: 22px;}
	.e-boton { display: block;}
	.e-boton a{padding: 18px;
    background: #f8b56d;
    color: #fff;
    display: block;
    width: 75%;
    text-align: center;
    margin: auto;
    margin-bottom: 9px;
}



#cdk-overlay-1{width: 100%}
.mat-dialog-container{background: #f8f3df;}
.e-boton a:hover{background: Black}
	.signature{display: flex;flex-flow: column-reverse;border-bottom: 40px solid;}
	.signature-text{width:100%;min-height: 300px;}
	.signature-plan {width: 90%;margin: auto;margin-top: 26px;}
	.s-text-left{width:100%;overflow: visible;}
	.s-text-left img {width: 100%;}
	.s-text-left{margin: -100px 30px 0 30px;    width: 70%;}
	.s-text{margin-right: 0;display: flex;flex-flow: row-reverse;}
	.s-text-right{margin-left: 0;width: 45%;margin-top: 0}
	.s-text-right img {width: fit-content;height: 500px;}
	.s-plan{width: 100%;margin:0;height: 100%;}
	.s-plan #first-txt{font-size: 22px;line-height: 0}
	.t-plan p {font-size: 22px;line-height: 28px;text-align: center}
	.s-plan h2{font-size: 30px;text-align: center;padding-bottom: 20px;}
	.s-boton {margin: auto!important;width: 100%;padding-bottom: 25px;}
	
	.unete{margin: 7%}
	.unete h1 {font-size: 22px;text-align: justify;}
	.unete-w {width: 100%;}
	.unete a{width: 50%}
	.two-column{width: 100%;display: block;}
	.column-a{width: 100%}
	.column-a h1{    font-size: 26px}
	.column-a h1,.column-a p{margin-left: 30px;}
	.column-b {width: 90%;display: block;margin: 7%;}
	.wine-box {margin: 7%;}
	.wine {  width: 100%;}
	.column-plan{display: block}
	.column-plan-a{width: 80%; padding: 40px 40px 0 40px}
	.column-plan-a p{text-align: justify;}
	.plan-select{margin:7%}
	.column-plan-b{width: 87%}
	.column-plan-b img {width: -webkit-fill-available;}
	.content-form{margin:7%}
	.text-send-space{padding: 7%}
	.text-head {padding: 60px 7%;}
	.two-column-form{display: block;}
	.form-group {  width: 90%;margin: 20px 1px;}
	.form-group-solo{margin:1px;width: 90%;}
	.content-form h1 {font-size: 18px;TEXT-ALIGN: center;LINE-HEIGHT: 23PX;}
  /*Pop-up Phone iniciar*/

  .mobile-hide{display: block!important}
  .overlay-container{display:none!important}
  .sign-in-container, .sign-up-container{width: 100%!important;transform: translateX(0)!important;}
  .mat-header-row{
    display: none!important;
  }
  .mat-sort-header {
    padding-left: 15px!important;
    border-bottom: 1px solid #f0f0f0;
  }
  .mat-table{
    display: grid;
    grid-template-columns: 100%;
  }
  .tdc .mat-row {
    display: block;
    min-height: 60vw;
    margin-bottom: 14px;
  }
  .tdc{ background-image: url("assets/image/tdc.png");    background-size: contain;background-repeat: no-repeat}
  .cdk-column-maskCreditCard {
    display: block;
    position: absolute;
    color: white;
    right: 28%;
    font-size: 20px;
    margin-top: 27.6%;
  }
  .cdk-column-maskCreditCard:before {
    content: "XXXX XXXX";
  }
  .cdk-column-expMonthCreditCard {
    color: white;
    display: inline;
    position: absolute;
    right: 52px!important;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 47%;
  }
  .cdk-column-nameHolderCreditCard {
    display: block;
    position: absolute;
    color: white;
    font-size: 20px;
    left: 30%;
    margin-top: 16%;
  }
  .mat-column-expMonth {
    display: block;
    position: absolute;
    color: white;
    left: 71%;
    margin-top: 37%;
    font-size: 12px;
  }
  .cdk-column-expYearCreditCard {
    display: block;
    position: absolute;
    color: white;
    right: 21%;
    margin-top: 40%;
  }
  .cdk-column-createdAt {
    display: block;
    position: absolute;
    color: white;
    margin-top: 48%;
    left: 10%;
  }
  .cdk-column-createdAt:before{
    content: "Creada: ";
  }

  .cdk-column-estatus {
    display: block;
    position: absolute;
    color: white;
    right: 35px;
    margin-top: 9px;
    font-weight: bold;
  }
  .cdk-column-actions {
    display: block;
    position: absolute;
    margin-top: -4px;
  }
}
.mobile-hide{display: none}

.table-container {
  overflow-x: auto;
  padding: 4%;
background: whiteF7F7F7;
}
.table-container a{
  padding: 10px 0;
  display: block;
}
.table-container h1{
  color:black;
  position: relative;
  margin-bottom: 50px;
}
.table-container h1:after {
  content: "";
  display: block;
  width: 45%;
  border-bottom: 2px solid black;
  position: absolute;
  bottom: 24px;
  right: 0
}
.table-container th button {
  background: #711b45;
  color: white;
  border: none;
  padding: 15px 40px;
  font-family: 'Gill Sans';
}
.table-container th button:hover {
background:black;
}

.table-container h2 {
  min-height: 0px;}
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 22px 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  padding: 0;
}

/* correccion boton */
@media (max-width:1600px){
.plan-e p, .plan-s {
	min-height: 8vw
}
}

/* Set a style for all buttons */


/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

/* The Modal (background) */
.modal {
 /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)}
  to {-webkit-transform: scale(1)}
}

@keyframes animatezoom {
  from {transform: scale(0)}
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}
.modal h1 {
	font-weight: bold;
	margin: 0;
	font-size: 35px;

}

.modal h2 {
	text-align: center;
}

.modal p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.modal span {
	font-size: 12px;
}

.modal a {
	color: #333;
	font-size: 12px;
	text-decoration: none;
	margin: 15px 0;
}

.modal button {
	border-radius: 20px;
	border: 1px solid #711B45;
	background-color: #711B45;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.modal button:active {
	transform: scale(0.95);
}

.modal button:focus {
	outline: none;
}

button.ghost {
  background-color: #FFFFFF;
  color: black;
	border-color: #FFFFFF;
}

.modal form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.modal input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.modal .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25),
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
	margin:auto;
	top:15%
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.modal .container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.modal .container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}



.modal .container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #711B45;
  background-image: url(assets/image/banner-left.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}
.overlay:before {
    content: "/";
    background: #00000091;
    padding: 100%;
}
.modal .container.right-panel-active .overlay {
  	transform: translateX(50%);
}



.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}
.custom-slide-toggle .mat-slide-toggle-bar{
  background-color: #fdd7ae;
}
.custom-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #a4a4a4;
}

.custom-slide-toggle .mat-slide-toggle-thumb{
  background-color: #f8b56d;
}

.custom-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: black;
}

.custom-slide-toggle .mat-slide-toggle-thumb:before,
.custom-slide-toggle.mat-checked .mat-slide-toggle-thumb:before {
  background-color: #f8b56d;
}